<template>
  <div class="card-wrapper">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "CardBasic",
};
</script>

<style scoped>
.card-wrapper {
  background: #ffffff;
  border-radius: 8px;
  padding: 20px;
}
@media (min-width: 768px) {
  .card-wrapper {
    padding: 24px;
  }
}
</style>
