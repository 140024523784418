<template>
  <div class="onboarding-page__wrapper">
    <div class="approved-page">
      <h2 class="title">
        함께 하게 되어 기뻐요 :) <br />
        마지막으로 스튜디오 이용 시 <br class="mo" />
        주의사항을 확인해주세요
      </h2>
      <p class="sub-text-s2 text-gray-second">
        빅크는 아래 행위를 철저히 금지하고 있어요.
      </p>

      <card-basic class="precautions-card">
        <ul class="precaution-list">
          <li>
            <info-icon fill-color="#FF4646" :fill="true"></info-icon>
            <span class="b-text-2 mo">
              타인을 사칭, 명예 및 신용을 훼손시키는 행위
            </span>
            <span class="b-text-2 pc">
              타인을 사칭 또는 타인의 명예 및 신용을 훼손시키는 행위
            </span>
          </li>
          <li>
            <info-icon fill-color="#FF4646" :fill="true"></info-icon>
            <span class="b-text-2 mo">
              금전적 손해를 가할 목적으로 정보 유통하는 행위
            </span>
            <span class="b-text-2 pc">
              타인에게 금전적 손해를 가할 목적으로 정보를 유통하는 행위
            </span>
          </li>
          <li>
            <info-icon fill-color="#FF4646" :fill="true"></info-icon>
            <span class="b-text-2 mo">
              타인의 초상권 및 지식재산권을 침해하는 행위
            </span>
            <span class="b-text-2 pc">
              회사 또는 타인의 초상권 및 지식재산권을 침해하는 행위
            </span>
          </li>
          <li>
            <info-icon fill-color="#FF4646" :fill="true"></info-icon>
            <span class="b-text-2 mo">
              타인에게 수치심, 혐오감, 공포감 등을 주는 행위
            </span>
            <span class="b-text-2 pc">
              음란성, 잔인성, 사행성 등 타인에게 수치심 또는 혐오감, 공포감을
              주는 행위
            </span>
          </li>
          <li>
            <info-icon fill-color="#FF4646" :fill="true"></info-icon>
            <span class="b-text-2 mo">
              기타 관계 법령에 위배되는 불법 및 부당한 행위
            </span>
            <span class="b-text-2 pc">
              기타 관계 법령에 위배되는 불법적이거나 부당한 행위
            </span>
          </li>
        </ul>

        <button-basic
          class="policy-btn"
          text="빅크 이용약관 자세히 보기"
          text-size="s3"
          bg-color="#ffffff"
          color="#0D0D10"
          padding="8px 0"
          border-color="#E6EAEF"
          :border="true"
          @action="actions.goToPolicy()"
        ></button-basic>
      </card-basic>

      <p class="sub-text-s3 text-gray-second">
        위 내용에 대한 위반 사항이 확인될 경우 내부 검수 기준에 따라 브랜드 홈
        사용 및 프리미엄 콘텐츠 판매가 별도 안내없이 중지될 수 있어요.
      </p>

      <button-basic
        class="save-btn"
        text="네, 확인했어요"
        @action="actions.onBoardingFinish()"
      ></button-basic>
    </div>
  </div>
</template>

<script>
import CardBasic from "../../../../components/console/cards/CardBasic";
import InfoIcon from "../../../../components/console/icons/InfoIcon";
import ButtonBasic from "../../../../components/console/buttons/ButtonBasic";
import helper from "@/helper";
import { useStore } from "vuex";
import onBoardingConst from "../../../../consts/onboarding";
import { useRouter } from "vue-router";
import { computed, reactive } from "vue";

export default {
  name: "OnBoardingApproved",
  components: {
    ButtonBasic,
    InfoIcon,
    CardBasic,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const state = reactive({
      user: computed(() => {
        return store.getters["auth/user"];
      }),
    });

    const actions = {
      goToPolicy: () => {
        helper.goToExternalUrl(
          "https://bigc-im.notion.site/0a271dd289b74905a9345c908a01e95c"
        );
      },
      onBoardingFinish: () => {
        store
          .dispatch("onBoardings/putOnBoardingStep", {
            step: onBoardingConst.onBoardingSteps.finish,
          })
          .then(async () => {
            if (!state.user.aliasName) {
              await store.dispatch("auth/setWelcomeModalSession");
            }

            await router.push({ name: "console.home" });
          });
      },
    };

    return { actions };
  },
};
</script>

<style src="./style.css" scoped></style>
